import { cn } from '#app/utils/misc.tsx';
import * as React from 'react';

export interface InputProps
	extends React.InputHTMLAttributes<HTMLInputElement> {  }

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        className={cn(
          'flex h-full w-full rounded-lg bg-background border-input border border-gray-30',
          'text-label-1-medium xs:font-sfProMedium sm:font-pretendardMedium placeholder:text-muted-foreground placeholder:text-gray-50',
          'pl-5 pr-4 py-4 aria-[invalid]:border-input-invalid',
          'active:border-gray-30 focus:border-gray-90 focus:ring-0 focus:ring-offset-0 focus:ring-offset-transparent outline-none',
          'disabled:cursor-not-allowed disabled:opacity-50',
          className,
        )}
        ref={ref}
        type={type}
        {...props}
      />
    );
  },
);
Input.displayName = 'Input';

export { Input };
