/* eslint-disable max-lines */
import { cn } from '#app/utils/misc.tsx';
import { cva } from 'class-variance-authority';
import React, { useId, useRef } from 'react';

import { Icon } from './ui/icon.tsx';
import { Input } from './ui/input.tsx';
import { Label } from './ui/label.tsx';

export type ListOfErrors = Array<null | string | undefined> | null | undefined;

export function ErrorList({
  errors,
  id,
}: {
	errors?: ListOfErrors
	id?: string
}) {
  const errorsToRender = errors?.filter(Boolean);
  if (!errorsToRender?.length) { return null; }
  return (
    <ul className="flex flex-col gap-1" id={id}>
      {errorsToRender.map(e => (
        <li className="text-caption-1-regular xs:font-sfProLight sm:font-pretendardLight text-foreground-destructive" key={e}>
          {e}
        </li>
      ))}
    </ul>
  );
}

export function Field({
  className,
  errors,
  inputProps,
  labelProps,
}: {
	className?: string
	errors?: ListOfErrors
	inputProps: React.InputHTMLAttributes<HTMLInputElement>
	labelProps?: {
    description?: string;
  } & React.LabelHTMLAttributes<HTMLLabelElement>
}) {
  const fallbackId = useId();
  const id = inputProps.id ?? fallbackId;
  const errorId = errors?.length ? `${id}-error` : undefined;

  const error: Record<'input' | 'label', string> = {
    input: 'border-error-darkRed active:border-error-darkRed focus:border-error-darkRed',
    label: 'text-error-darkRed'
  };


  return (
    <div className='grid grid-flow-row'>
      {labelProps && <Label 
        htmlFor={id}
        {...labelProps}
        className={
          cn(
            'text-gray-100 font-bold text-label-2-bold xs:font-sfProBold sm:font-pretendardBold',
            'pb-2.5'
          )
        } 
      />}
      <div className='min-w-[18rem] min-h-[3.25rem]'>
        <Input
          aria-describedby={errorId}
          aria-invalid={errorId ? true : undefined}
          autoComplete={'off'}
          className={errorId ? error['input'] : ''}
          id={id}
          {...inputProps}
        />
      </div>
      {
        (errorId && !labelProps?.description)&&
         <div className="min-h-[2rem] pt-1.5">
           <ErrorList errors={errors} id={errorId} />
         </div>
      }
      {
        (!errorId && labelProps?.description) &&
        <div className="min-h-[2rem] pt-1.5">
          <div className="text-caption-1-regular xs:font-sfProLight sm:font-pretendardLight text-gray-50">
            {labelProps.description}
          </div>
        </div>
      }
    </div>
  );
}

interface Item {
  disabled?: boolean;
  id?: number;
  label: string
  value: string
}

export function Autocomplete({
  className,
  error,
  expanded,
  inputProps,
  labelProps,
  menuProps,
}: {
	className?: string
	error?: boolean
	expanded: boolean
  inputProps: React.InputHTMLAttributes<HTMLInputElement>
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>
  menuProps: {
    items: Item[];
    loading?: boolean;
    selected: string | undefined;
    setSelected: (item: {
      id?: number;
      label: string | undefined;
      value: string | undefined;
    }, type: 'click' | 'enter') => void
  }
}) {

  const fallbackId = useId();
  const id = inputProps.id ?? fallbackId;

  const errors: Record<'input' | 'label', string> = {
    input: 'border-error-darkRed active:border-error-darkRed focus:border-error-darkRed',
    label: 'text-error-darkRed'
  };

  const inputRef = useRef<HTMLInputElement>(null);


  React.useEffect(() => {
    if (inputProps.autoFocus === false) {
      void inputRef.current?.blur();
    }
  }, [inputProps.autoFocus]);


  return (
    <div className='grid grid-flow-row gap-2.5'>
      {labelProps && 
        <Label
          htmlFor={id}
          {...labelProps}
          className={
            cn(
              'text-gray-100 font-bold text-label-2-bold xs:font-sfProBold sm:font-pretendardBold',
              error ? errors['label'] : '',
            )
          }
        />
      }
      <div className={cn('relative min-w-[18rem] min-h-[1.25rem] max-h-[3.25rem]', inputProps.className,)}>
        <Input
          autoComplete={'off'}
          className={error ? errors['input'] : ''}
          id={id}
          name={inputProps.name}
          ref={inputRef}
          {...inputProps}
        />
        <span className='absolute right-0 bottom-0 mb-4 mr-4'>
          {
            expanded 
              ? <Icon className='animate-rotate-180' name='thin_expand_more' size={'medium'} /> 
              : <Icon className='animate-rotate-0' name='thin_expand_more' size={'medium'} />
          }
        </span>
        <div>
          <Menu open={expanded}>
            {
              !menuProps.loading
                ? menuProps.items.length > 0
                  ? menuProps.items.map((value, index) => {
                    return (
                      <MenuItem
                        disabled={value.disabled ?? false}
                        index={index}
                        key={index}
                        label={value.label}
                        onSelect={(selectedValue, selected) => {
                          if (value.disabled) {
                            return;
                          }
                          if (selected) {
                            return;
                          }
                          void menuProps.setSelected({id: value.id, label: value.label, value: value.value}, 'click');
                        }}
                        selectedValue={value.value === menuProps.selected}
                        value={`${value.value}`}
                      />
                    );
                  })
                  : <MenuItem
                    disabled={false}
                    empty={true}
                    index={0}
                    key={'empty'}
                    label={'결과가 없어요.'}
                    onSelect={(selectedValue, selected) => {}}
                    selectedValue={false}
                    value={'결과가 없어요.'}
                  />
                : Array.from({ length: 4 }).map((e, i) => (
                  <div className='px-4 py-2.5' key={i}>
                    <div className='w-full rounded-md bg-gray-20 h-6' />
                  </div>
                ))
            }
           
          </Menu>
        </div>
      </div>    
    </div>
  );
}


function Menu({
  children,
  open
}: {
  children: React.ReactNode
  open: boolean
}) {
  return (
    <>
      {open && (
        <div className='relative z-50 max-h-[17.125rem] shadow-lg overflow-auto mt-2.5 grid py-3.5 rounded-xl border bg-background min-h-[4rem]' tabIndex={0}>
          {children}
        </div>
      )}
    </>
  );
}

interface MenuItemProps {
  className?:string;
  disabled: boolean;
  empty?: boolean;
  index: number;
  label: string;
  onSelect: (value: string, selected: boolean) => void
  selectedValue: boolean;

  value: string;
}
export function MenuItem({ className, disabled, empty, index, label, onSelect, selectedValue, value }: MenuItemProps) {
  const cv = cva(
    'z-[9] px-4 py-2.5 cursor-pointer hover:bg-gray-10 hover:text-point-blue-70 max-h-[2.625rem]', {
      compoundVariants: [
        {
          className: 'text-gray-40',
          empty    : true,
          selected : false,
        }
      ],
      defaultVariants: {
        selected: false,
      },
      variants: {
        disabled: {
          true: 'text-gray-40 cursor-not-allowed pointer-events-none',
        },
        empty: {
          true: 'text-label-1-regular xs:font-sfProLight sm:font-pretendardLight pointer-events-none'
        },
        selected: {
          false: 'text-label-1-regular xs:font-sfProLight sm:font-pretendardLight text-gray-90',
          true : 'text-label-1-bold text-point-blue-70 xs:font-sfProBold sm:font-pretendardBold'
        }
      }
    }
  );

  return (
    <div 
      className={
        cn(
          cv({ disabled, empty, selected: selectedValue }),
          className
        )
      }
      onClick={() => {
        if (disabled || empty) {
          return;
        }
        onSelect(value, selectedValue);
      }}
    >
      {label}
    </div>
  );
}


export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>
  }

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, labelProps, ...props }, ref) => {
    const fallbackId = useId();

    return (
      <div className='grid grid-flow-row gap-2.5'>
        {labelProps &&
          <Label
            htmlFor={fallbackId}
            {...labelProps}
            className={
              cn('text-gray-100 text-label-2-bold xs:font-sfProBold sm:font-pretendardBold')
            }
          />
        }
        <textarea
          className={cn(
            'flex text-label-1-medium xs:font-sfProMedium sm:font-pretendardMedium text-gray-90 min-h-[60px] rounded-lg w-full bg-transparent placeholder:text-muted-foreground placeholder:text-gray-50',
            'pl-5 pr-4 py-4 bg-background border-input border border-gray-30',
            'active:border-gray-30 focus:border-gray-90 focus:ring-0 focus:ring-offset-0 focus:ring-offset-transparent outline-none',
            className
          )}
          ref={ref}
          {...props}
        />
      </div>
    );
  }
);

Textarea.displayName = 'Textarea';



